/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------
==========================================================================================*/

.progress {

    // border radius to set for stacked bars
    .progress-bar+.progress-bar:not(:last-child) {
        border-radius: 0;
    }

    .progress-bar:last-child {
        border-top-right-radius: $progress-border-radius;
        border-bottom-right-radius: $progress-border-radius;
    }
}

.progress-bar-percentage {
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
}